import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {GET_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {GetState} from '../types/state'
import {updateNoEventsUrl} from './navigation'

export const GET_COMPONENT_DATA = createActions('GET_COMPONENT_DATA')
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_COMPONENT_DRAFT = createActions('UPDATE_COMPONENT_DRAFT')
export const UPDATE_COMPONENT = createActions('UPDATE_COMPONENT')

export const getComponentData = (responsive: boolean) => async (dispatch: Function) => {
  const {siteSettings: settings} = await dispatch(callAPI(GET_COMPONENT_DATA, responsive))
  dispatch({type: GET_SITE_SETTINGS.SUCCESS, payload: {settings}})
  return dispatch(updateNoEventsUrl())
}

export const updateComponentSettings = (settings: object) => (dispatch: Function) => {
  dispatch({type: UPDATE_SETTINGS, payload: settings})
  return dispatch(updateNoEventsUrl())
}

export const updateComponentDraft = (component: wix.events.editor.WebComponentConfig) =>
  callAPI(UPDATE_COMPONENT_DRAFT, component)

export const updateComponent = () => (dispatch: Function, getState: GetState) => {
  dispatch(callAPI(UPDATE_COMPONENT, {settings: getState().component.settings}))
}
