import _ from 'lodash'
import {MembersSettingsEvents} from '../../commons/enums'
import {getEventList} from '../selectors/events'
import {GetState} from '../types/state'
import {updateComponentDraft, updateComponentSettings} from './component'
import {closeAllEvents, toggleEventDetails} from './events'
import {showDemoEvents, showNoEvents} from './view'

interface SettingsUpdateAction {
  type: MembersSettingsEvents
  [key: string]: any
}

export const updateSettings = (action: SettingsUpdateAction) => (dispatch: Function, getState: GetState) => {
  switch (action.type) {
    case MembersSettingsEvents.SETTINGS:
      debouncedUpdateComponentDraft(getState, action, dispatch)
      dispatch(updateComponentSettings(action.settings))
      break
    case MembersSettingsEvents.OPEN_NO_EVENTS:
      dispatch(showNoEvents(true))
      break
    case MembersSettingsEvents.OPEN_DETAILS:
      dispatch(showNoEvents(false))
      const events = getEventList(getState())
      events.slice(0, 2).forEach((event) => dispatch(toggleEventDetails(event)))
      break
    case MembersSettingsEvents.OPEN_DEFAULT:
      dispatch(showNoEvents(false))
      dispatch(showDemoEvents())
      dispatch(closeAllEvents())
      break
    default:
      break
  }
}

const debouncedUpdateComponentDraft = _.debounce((getState: GetState, action, dispatch: Function) => {
  const state = getState()
  dispatch(updateComponentDraft({settings: state.component.settings}))
}, 1000)
