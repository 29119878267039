import {AnyAction} from 'redux'
import {
  SET_CALENDAR_REFERENCE_DATE,
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
} from '../actions/calendar-layout'
import {LOAD_CALENDAR_EVENTS} from '../actions/events'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  today: null,
  month: {},
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
  days: {},
  weekDayNames: [],
  loadedPages: [],
  events: {},
  demoEvents: {},
  loading: null,
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case SET_CALENDAR_REFERENCE_DATE:
      return {
        ...state,
        referenceDate: action.payload,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
      }
    case LOAD_CALENDAR_EVENTS.REQUEST:
      return {
        ...state,
        loading: action.args[1],
      }
    case LOAD_CALENDAR_EVENTS.SUCCESS: {
      const reset = action.args[2]
      const {referenceDate, weekDayNames, month, today, events, days} = action.payload.dates.calendar
      return {
        ...state,
        referenceDate,
        today,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        weekDayNames,
        month: {
          ...state.month,
          ...month,
        },
        events: reset ? events : {...state.events, ...events},
        days: {...state.days, ...days},
        loadedPages: reset ? [referenceDate] : [...state.loadedPages, referenceDate],
        loading: null,
      }
    }
    case LOAD_CALENDAR_EVENTS.FAILURE:
      return {
        ...state,
        loading: null,
      }
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.day,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.day,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
