import {RIBBON} from '../../commons/constants/ribbons'

export const OPEN_LIST_LAYOUT_ITEM = 'OPEN_LIST_LAYOUT_ITEM'
export const CLOSE_LIST_LAYOUT_ITEMS = 'CLOSE_LIST_LAYOUT_ITEMS'
export const WIDGET_LOADED = 'WIDGET_LOADED'
export const SHOW_RIBBONS = 'SHOW_RIBBONS'
export const HIDE_RIBBONS = 'HIDE_RIBBONS'

export const openListLayoutItem = (eventId: string) => ({type: OPEN_LIST_LAYOUT_ITEM, payload: eventId})

export const closeListLayoutItems = () => ({type: CLOSE_LIST_LAYOUT_ITEMS})

export const widgetLoaded = (isHomePage: boolean) => ({type: WIDGET_LOADED, isHomePage})

export const showRibbonOnItems = (ribbon?: RIBBON) => ({type: SHOW_RIBBONS, payload: ribbon})

export const hideRibbonOnItems = () => ({type: HIDE_RIBBONS})
