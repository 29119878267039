import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'

export const instanceGetterFactory = (controller: IWidgetControllerConfig) => {
  let instance = controller.appParams.instance
  onInstanceChanged(controller, (newInstance) => (instance = newInstance))
  return () => instance
}

export const onInstanceChanged = (controller: IWidgetControllerConfig, callback: (instance: string) => void) =>
  controller.wixCodeApi.site.onInstanceChanged((event) => callback(event.instance), EVENTS_APP_ID)
