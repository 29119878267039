import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import Experiments from '@wix/wix-experiments'
import {MAIN_DC_URL} from '@wix/wix-events-commons-statics'
import {EXPERIMENTS_SCOPE} from '../constants/app'
import {getBaseUrl} from './wix-code-api'

export const getExperimentsByScope = async (wixCodeApi: IWixAPI, scope: string = EXPERIMENTS_SCOPE) => {
  const fallbackUrl = wixCodeApi.window.viewMode === 'Site' ? '' : MAIN_DC_URL
  const baseUrl = getBaseUrl(wixCodeApi, fallbackUrl)
  const experiments = new Experiments({scope, baseUrl})
  await experiments.ready()
  return experiments.all()
}
