import {AnyAction} from 'redux'
import {CLOSE_LIST_LAYOUT_ITEMS, HIDE_RIBBONS, OPEN_LIST_LAYOUT_ITEM, SHOW_RIBBONS} from '../actions/layout'
import {Layout} from '../types/state'

const defaultState: Layout = {
  listItemOpened: '',
  forceShowRibbons: false,
  forceShowRibbon: null,
}

export const layout = (state = defaultState, action: AnyAction): Layout => {
  switch (action.type) {
    case OPEN_LIST_LAYOUT_ITEM:
      return {
        ...state,
        listItemOpened: action.payload,
      }
    case CLOSE_LIST_LAYOUT_ITEMS:
      return {
        ...state,
        listItemOpened: '',
      }
    case SHOW_RIBBONS:
      return {
        ...state,
        forceShowRibbons: !action.payload,
        forceShowRibbon: action.payload ?? null,
      }
    case HIDE_RIBBONS:
      return {
        ...state,
        forceShowRibbons: false,
        forceShowRibbon: null,
      }
    default:
      return state
  }
}
