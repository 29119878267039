import {combineReducers} from 'redux'
import {environment} from '../../commons/reducers/environment'
import {experiments} from '../../commons/reducers/experiments'
import {multilingual} from '../../commons/reducers/multilingual'
import {siteSettings} from '../../commons/reducers/site-settings'
import {MemberPageState} from '../types/state'
import {dates} from '../../commons/reducers/dates'
import {component} from './component'
import {demoEvents} from './demo-events'
import {eventsReducer as events} from './events'
import {expandedEvents} from './expanded-events'
import {loading} from './loading'
import {navigation} from './navigation'
import {tab} from './tab'
import {user} from './user'
import {viewReducer} from './view'
import {tickets} from './tickets'

export default combineReducers<MemberPageState>({
  experiments,
  user,
  environment,
  events,
  component,
  expandedEvents,
  siteSettings,
  tab,
  loading,
  navigation,
  demoEvents,
  view: viewReducer,
  multilingual,
  tickets,
  dates,
})
