import _ from 'lodash'

export const convertFromNewToOldSettings = (compSettings: WidgetComponentSettings, obsoleteKeys: string[]) => {
  const newSettings: any = _.omit(
    _.pick(compSettings, [
      'widgetType',
      'widgetLayout',
      'imagePosition',
      'verticalImagePosition',
      'imageScaling',
      'imageGridPosition',
      'alignment',
      'contentAlignment',
      'listLayout',
      'imageRatio',
      'listAlignment',
      'listImageLayout',
      'cardsPerRow',
    ]),
    obsoleteKeys,
  )

  const settings: any = {}

  settings.texts = compSettings.texts

  if (newSettings.cardsPerRow) {
    settings.cardsPerRow = newSettings.cardsPerRow
  }

  Object.keys(newSettings).forEach((key: string) => {
    if (oldToNewMap[key]) {
      settings[key] = getOldValue(key, newSettings[key])
    }
  })

  return settings
}

const oldToNewMap: any = {
  widgetType: {
    SINGLE: 1,
    LIST: 2,
  },
  widgetLayout: {
    FULL: 1,
    BUTTON_ONLY: 2,
    VERTICAL: 3,
    BACKGROUND: 4,
  },
  imagePosition: {
    LEFT: 1,
    RIGHT: 2,
  },
  verticalImagePosition: {
    TOP: 1,
    BOTTOM: 2,
  },
  imageScaling: {
    FIT: 1,
    CROP: 2,
  },
  imageGridPosition: {
    TOP_LEFT: 1,
    TOP_CENTER: 2,
    TOP_RIGHT: 3,
    CENTER_LEFT: 4,
    CENTER: 5,
    CENTER_RIGHT: 6,
    BOTTOM_LEFT: 7,
    BOTTOM_CENTER: 8,
    BOTTOM_RIGHT: 9,
  },
  alignment: {
    LEFT: 1,
    CENTER: 2,
    RIGHT: 3,
  },
  contentAlignment: {
    TOP: 1,
    CENTER: 2,
    BOTTOM: 3,
  },
  listLayout: {
    LIST: 1,
    GRID: 2,
    NEW_GRID: 3,
    SIDE_BY_SIDE: 4,
  },
  imageRatio: {
    '1:1': 1,
    '16:9': 2,
  },
  listAlignment: {
    LEFT: 1,
    CENTER: 2,
    RIGHT: 3,
  },
  listImageLayout: {
    LEFT: 1,
    MIXED: 2,
    RIGHT: 3,
  },
}

export const getOldValue = (key: string, value: number) =>
  Object.entries(oldToNewMap[key]).find(([nKey, nValue]: any) => nValue === value)[0]
