import {AnyAction} from 'redux'
import {
  INJECT_COMPONENT_DRAFT,
  UPDATE_COMPONENT_DRAFT,
  UPDATE_COMPONENT_TEXTS,
  UPDATE_STYLE_PARAMS,
} from '../actions/component'
import {SettingsUpdatedActionType} from '../actions/sdk'
import {State} from '../types/state'

const defaultState: WidgetComponentConfig = {} as WidgetComponentConfig

export const component = (state = defaultState, action: AnyAction): WidgetComponentConfig => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
        params: {
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    case UPDATE_COMPONENT_DRAFT.REQUEST:
      const [type, componentArg] = action.args
      const eventVisibilityTypes = [SettingsUpdatedActionType.HIDE_EVENT, SettingsUpdatedActionType.DISPLAY_EVENT]

      if (eventVisibilityTypes.includes(type)) {
        return {
          ...state,
          byEventId: componentArg && componentArg.byEventId,
        }
      } else {
        return state
      }
    case INJECT_COMPONENT_DRAFT:
    case UPDATE_COMPONENT_DRAFT.SUCCESS:
      return {
        ...state,
        byEventId: action.payload.component.config.byEventId,
        byStatus: action.payload.component.config.byStatus,
        byCategoryId: action.payload.component.config.byCategoryId,
      }
    case UPDATE_COMPONENT_TEXTS:
      return {
        ...state,
        settings: {
          ...state.settings,
          texts: action.payload,
        },
      }
    default:
      return state
  }
}

export const getComponentEventIds = (state: State) => state.component.byEventId?.eventId
