import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {applyMiddleware, createStore, PreloadedState, Middleware, Reducer, Store} from 'redux'
import thunk from 'redux-thunk'

interface StoreConfig<State, StoreExtraArgs> {
  reducers: Reducer
  initialData: PreloadedState<State>
  extraArguments: StoreExtraArgs
  middleware: Middleware[]
}

export const createReduxStore = <State, StoreExtraArgs>({
  reducers,
  initialData,
  extraArguments,
  middleware,
}: StoreConfig<State, StoreExtraArgs>) =>
  createStore(reducers, initialData, applyMiddleware(thunk.withExtraArgument(extraArguments), ...middleware))

export const subscribeToStateChanges = (controller: IWidgetControllerConfig, store: Store) => {
  const onStateChange = () => {
    const state = store.getState()
    controller.setProps({state})
  }

  store.subscribe(onStateChange)
}
